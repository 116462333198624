import { useEffect, useState } from "react";
import CardContainerComponent from "./CardContainerComponent";
import {
  connect,
  deployErc20,
  getAddress,
  getUserChain,
} from "../ethersConnector";
import DeploymentComponent from "./DeploymentComponent";
import UserDeploymentsComponent from "./UserDeploymentsComponent";
import { useNavigate } from 'react-router-dom';

const FactoryComponent = ({ style, resetHomePage }) => {//, setResetHomePage

  const navigate = useNavigate();
const setDisplayComponent = (endpoint)=>navigate(endpoint);
  //const getDisplayComponent = () => {
  //  var saved = localStorage.getItem("deployDisplayComponent");
  //  return saved || "menu";
  //};
  //const [displayComponent, setDisplayComponent] = useState(
  //  getDisplayComponent()
  //);
  //const [deployPage, setDeployPage] = useState("");
  //useEffect(() => {
  //  window.scrollTo({
  //    top: 0,
  //    left: 0,
  //    behavior: 'smooth' // Optional
  //  });
  //}, [deployPage,displayComponent]);


  //useEffect(() => {
  //  if (resetHomePage) {
  //    setDisplayComponent("menu");
  //  }
    //setResetHomePage(false);
  //}, [resetHomePage]);

  //useEffect(() => {
  //  localStorage.setItem("deployDisplayComponent", displayComponent);
  //}, [displayComponent]);
/**
  if (displayComponent == "Create ERC20") {
    return (
      <div className="deploymentContainer">
        <DeploymentComponent
          title="Create ERC20"
          //deployPage={deployPage}
          //setDeployPage={setDeployPage}
          userDeployments={userDeployments}
          setUserDeployments={setUserDeployments}
          localStorage_deploymentVariables={localStorage_deploymentVariables}
          localStorage_userDeployments={localStorage_userDeployments}
        />

        { ( 
          <div
            className="wideButton"
            onClick={() => {
              navigate("deployments");
              //setDeployPage("userDeployments");
            }}
          >
            Deployed Tokens
          </div>
        )}
        <div
          className="backButton"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </div>
      </div>
    );
  }
 */
  return (
    <div>
      <CardContainerComponent onClickFunction={setDisplayComponent} />
    </div>
  );
};

export default FactoryComponent;

import "./App.css";

import { GoogleAnalytics, TrackPageViews } from "./components/GoogeAnalytics";
import {
  Link,
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { privacyPolicy, termsOfService } from "./legal";

import ConverterComponent from "./components/ConverterComponent";
import DeploymentComponent from "./components/DeploymentComponent";
import FactoryComponent from "./components/FactoryComponent";
import GastrackerComponent from "./components/GastrackerComponent";
import { Helmet } from "react-helmet";
import MenuComponent from "./components/MenuComponent";
import { SelectableChainsProvider } from "./SelectableChainsContext"; // Ensure the path is correct
import TextComponent from "./components/TextComponent";
import UserDeploymentsComponent from "./components/UserDeploymentsComponent";
import _ from "lodash"; //MM: check this
import { parseGweiToEth } from "./ethersConnector";
import { useLocation } from "react-router-dom";

const localStorage_deploymentVariables = "deploymentVariables_v04";
const localStorage_userDeployments = "userDeploymentsDetails_v04";

const defaultDeployInputs = {
  name: "",
  symbol: "",
  premint: "1000000000",
  maxSupply: "",
  //permit: "",
  premintable: true,
  mintable: false,
  burnable: true,
  pausable: false,
  permitable: true,
  capped: false,
  selectedChainId: "0x1",
};

const getSavedInputs = () => {
  var saved = localStorage.getItem(localStorage_deploymentVariables);
  //console.log("saved",saved);
  if (saved) {
    var obj = JSON.parse(saved);
    if (obj) {
      return obj;
    }
  }
  return defaultDeployInputs;
};
const feesDefault = {
  low: "0",
  mid: "0",
  high: "0",
  lowUsd: "0",
  midUsd: "0",
  highUsd: "0",
};

function App() {
  //const [displayComponent, setDisplayComponent] = useState("main");
  //const [resetHomePage, setResetHomePage] = useState(false);
  const [ethPrice, setEthPrice] = useState(0);
  const [fees, setFees] = useState(feesDefault);
  const [deployInputs, setDeployInputs] = useState(getSavedInputs());
  const [hideEthPrice, setHideEthPrice] = useState(true);

  //useEffect(() => {
  //  window.scrollTo({
  //    top: 0,
  //    left: 0,
  //    behavior: "smooth", // Optional
  //  });
  //}, [displayComponent, resetHomePage]);

  const getSavedUserDeployments = () => {
    var saved = localStorage.getItem(localStorage_userDeployments);
    //console.log("saved",saved);
    if (saved) {
      var obj = JSON.parse(saved);
      if (obj) {
        return obj;
      }
    }
    return [];
  };
  const [userDeployments, setUserDeployments] = useState(
    getSavedUserDeployments()
  );
  async function getEthPrice() {
    try {
      const apiUrl = "https://api.etherscan.io/api";
      const module = "stats";
      const action = "ethprice";
      const apiKey = "THEURR7CZA8BRW9N29XTQ3I1DTPCBI3IKI"; // Ersetzen Sie dies durch Ihren API-Schlüssel

      const fullUrl = `${apiUrl}?module=${module}&action=${action}&apikey=${apiKey}`;

      const response = await fetch(fullUrl);
      const data = await response.json();

      setEthPrice(parseFloat(data.result.ethusd).toFixed(2));
    } catch (error) {
      console.error("Fehler beim Abrufen des ETH-Preises:", error);
    }
  }

  async function getGasOracle() {
    try {
      const apiUrl = "https://api.etherscan.io/api";
      const module = "gastracker";
      const action = "gasoracle";
      const apiKey = "THEURR7CZA8BRW9N29XTQ3I1DTPCBI3IKI"; // Ersetzen Sie dies durch Ihren API-Schlüssel
      const standardTransactionCosts = 21000;
      const fullUrl = `${apiUrl}?module=${module}&action=${action}&apikey=${apiKey}`;

      const response = await fetch(fullUrl);
      const data = await response.json();
      //console.log("Data", data);

      const fee = {};
      fee.low = data.result.SafeGasPrice.toString();
      fee.mid = data.result.ProposeGasPrice.toString();
      fee.high = data.result.FastGasPrice.toString();

      if (ethPrice) {
        var lowInEth = parseGweiToEth(data.result.SafeGasPrice);
        var averageInEth = parseGweiToEth(data.result.ProposeGasPrice);
        var highInEth = parseGweiToEth(data.result.FastGasPrice);

        fee.lowUsd = parseFloat(
          lowInEth * ethPrice * standardTransactionCosts
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
        fee.midUsd = parseFloat(
          averageInEth * ethPrice * standardTransactionCosts
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
        fee.highUsd = parseFloat(
          highInEth * ethPrice * standardTransactionCosts
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
        console.log("fee", fee);
      }

      setFees(fee);
    } catch (error) {
      console.error("Fehler beim Abrufen des Gasorakel-Daten:", error);
    }
  }
  function ScrollToTop() {
    const { pathname } = useLocation();
    const previousPathname = useRef(pathname); // Initialize the ref with the current pathname
    useEffect(() => {
      setHideEthPrice(
        pathname === "/terms-of-service" || pathname === "/privacy-policy"
      );
      // Check if the current pathname is different from the previous pathname
      if (previousPathname.current !== pathname) {
        window.scrollTo(0, 0);
        previousPathname.current = pathname; // Update the ref to the current pathname after scrolling
        console.log("pathname", pathname);
      }
    }, [pathname]); // Only re-run the effect if pathname changes

    return null; // This component does not render anything
  }

  useEffect(() => {
    // Initial call with a small delay
    const timer = setTimeout(() => {
      getEthPrice();
      // Set up the interval after the initial call
      const interval = setInterval(() => {
        getEthPrice();
      }, 30000);
      // Clear the interval when the component unmounts
      return () => clearInterval(interval);
    }, 0);
    // Clear the timeout when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    try {
      console.log("getGasOracle..");
      getGasOracle();
    } catch (error) {
      console.log("getGasOracle-error", error);
    }
  }, [ethPrice]);

  useEffect(() => {
    console.log("fees triggered: ", JSON.stringify(fees));
  }, [fees]);

  return (
    <SelectableChainsProvider>
      <Helmet>
        <title>ERC20 Token Creation with Ethtimate</title>
        <meta
          name="description"
          content="How to create a token on Ethereum and many more networks made easy. Deploy your token in just minutes and without coding!"
        />
        <meta
          name="keywords"
          content="erc20 token development, create erc20 token, erc20 token creation, make erc20 token, l2 solutions, how to make a meme coin, how to create a meme coin, How to create a token on Ethereum"
        />
      </Helmet>
      <Router className="outerMostContainer">
        <GoogleAnalytics trackingId="G-K2FJZ9XZ89" />
        <TrackPageViews trackingId="G-K2FJZ9XZ89" />
        <ScrollToTop />
        <div className="App">
          <MenuComponent />
          {!hideEthPrice && (
            <div id="header" className="headerText">
              1 ETH = ${ethPrice}
            </div>
          )}
          <Routes>
            <Route path="/" element={<FactoryComponent />} />
            <Route
              path="/create-erc20"
              element={
                <DeploymentComponent
                  title="Create ERC20"
                  deployInputs={deployInputs}
                  setDeployInputs={setDeployInputs}
                  localStorage_deploymentVariables={
                    localStorage_deploymentVariables
                  }
                  localStorage_userDeployments={localStorage_userDeployments}
                  setUserDeployments={setUserDeployments}
                  userDeployments={userDeployments}
                />
              }
            />
            <Route
              path="/deployed-tokens"
              element={
                <UserDeploymentsComponent
                  userDeployments={userDeployments}
                  setUserDeployments={setUserDeployments}
                />
              }
            />
            <Route
              path="/gastracker"
              element={<GastrackerComponent fees={fees} />}
            />
            <Route
              path="/ethereum-unit-converter"
              element={<ConverterComponent />}
            />
            <Route
              path="/terms-of-service"
              element={<TextComponent content={termsOfService} />}
            />
            <Route
              path="/privacy-policy"
              element={<TextComponent content={privacyPolicy} />}
            />
            {/* Redirect all unknown paths to "/" */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <div style={{ margin: "6vh auto 20px auto", fontSize: "14px" }}>
            <Link to="/terms-of-service">Terms of Service</Link>
            <Link to="/privacy-policy" style={{ marginLeft: "20px" }}>
              Privacy Policy
            </Link>
          </div>
          <div style={{ minHeight: "40px" }}></div>
        </div>
      </Router>
    </SelectableChainsProvider>
  );
}
export default App;

import "../App.css";

import {
  ToEth,
  ToWei,
  addChainToMetaMask,
  addTokenToWallet,
  connect,
  deploy,
  deployErc20,
  deployExpensive,
  formatNumber,
  getAddress,
  getDecimals,
  getName,
  getSymbol,
  getUserChain,
  switchNetwork,
} from "../ethersConnector";
import { useEffect, useRef, useState } from "react";

import CardContainerComponent from "./CardContainerComponent";
import DropdownComponent from "./DropdownComponent";
import { Helmet } from "react-helmet";
import OptionalInputComponent from "./OptionalInputComponent";
import UserDeploymentsComponent from "./UserDeploymentsComponent";
import _ from "lodash";
import { getContract } from "../abis/templates";
import iconEthereum from "../assets/chainLogos/ethereum.svg";
import iconLoading from "../assets/loading.svg";
import { useNavigate } from "react-router-dom";
import { useSelectableChains } from "../SelectableChainsContext"; // Ensure the path is correct

const DeploymentComponent = ({
  style,
  title,
  //deployPage,
  //setDeployPage,
  userDeployments,
  setUserDeployments,
  localStorage_deploymentVariables,
  localStorage_userDeployments,
  deployInputs,
  setDeployInputs,
}) => {
  const [deployable, setDeployable] = useState(false);
  const [deployInfo, setDeployInfo] = useState("");
  const [isDeployInfoWarning, setIsDeployInfoWarning] = useState(false);
  const [deploySuccess, setDeploySuccess] = useState("");

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  // Create refs for each input and the button
  const nameInputRef = useRef(null);
  const symbolInputRef = useRef(null);
  const premintInputRef = useRef(null);
  const buttonRef = useRef(null);

  const getFactoryAddressFromChainId = (chainId) => {};

  useEffect(() => {
    var json = JSON.stringify(userDeployments);
    localStorage.setItem(localStorage_userDeployments, json);
  }, [userDeployments]);

  const handleInputChange_Name = (value) => {
    setDeployInputs({ ...deployInputs, name: value });
    //setName(event.target.value);
    //updateDeployButton();
  };
  const handleInputChange_Symbol = (value) => {
    setDeployInputs({ ...deployInputs, symbol: value });
    //setSymbol(event.target.value);
    //updateDeployButton();
  };
  const setPremint = (value) => {
    setDeployInputs({ ...deployInputs, premint: value });
    //setPremint(value);
    //updateDeployButton();
  };
  const setMaxSupply = (value) => {
    setDeployInputs({ ...deployInputs, maxSupply: value });
  };
  //const setPermit = (value) => {
  //  setDeployInputs({ ...deployInputs, permit: value });
  //};
  const setPremintable = (value) => {
    setDeployInputs({ ...deployInputs, premintable: value });
    //setPremint(value);
    //updateDeployButton();
  };
  const setMintable = (value) => {
    setDeployInputs({ ...deployInputs, mintable: value });
  };
  const setBurnable = (value) => {
    setDeployInputs({ ...deployInputs, burnable: value });
  };
  const setPausable = (value) => {
    setDeployInputs({ ...deployInputs, pausable: value });
  };
  const setPermitable = (value) => {
    setDeployInputs({ ...deployInputs, permitable: value });
  };
  const setCapped = (value) => {
    setDeployInputs({ ...deployInputs, capped: value });
  };
  const setSelectedChain = (value) => {
    setDeployInputs({ ...deployInputs, selectedChainId: value.id });
  };

  const handleKeyDown = (event, nextRef) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the form from being submitted
      if (nextRef) {
        nextRef.current && nextRef.current.focus(); // Move focus to the next input or button
      }
    }
  };
  const selectableChains = useSelectableChains();

  const handleButtonClick = async () => {
    setIsLoading(true); // Start loading
    setIsDeployInfoWarning(false);
    setDeployInfo("please continue in your wallet");
    setDeploySuccess("");
    const success = await connect(true);
    if (success) {
      const addr = await getAddress();
      const chainId = await getUserChain();
      const d = deployInputs;
      const selChain = selectableChains[d.selectedChainId];
      console.log(
        `Connected to ${addr} on chain ID ${chainId}, Selected: ${d.selectedChainId}`
      );
      if (chainId !== d.selectedChainId) {
        try {
          var change = await switchNetwork(d.selectedChainId);
          console.log("switchNetwork", change);
        } catch (error) {
          console.log("deploy-catch-error:", error);
          if (error.message.toString().includes("rejected")) {
            setIsDeployInfoWarning(true);
            setDeployInfo("network-switch rejected");
            setIsLoading(false);
            return;
          }
          try {
            var tryAddError = await addChainToMetaMask({
              chainId: d.selectedChainId,
              chainName: selChain.text,
              nativeCurrency: selChain.nativeCurrency,
              rpcUrls: selChain.rpcUrls,
            });
            console.log("addChainToMetaMask", tryAddError);
            if (tryAddError) {
              setIsDeployInfoWarning(true);
              setDeployInfo("adding network failed");
              setIsLoading(false);
              return;
            }
          } catch (error) {
            setIsDeployInfoWarning(true);
            setDeployInfo("adding network failed");
            setIsLoading(false);
            return;
          }
        }
      }
      const currentChainId = await getUserChain();
      if (currentChainId !== d.selectedChainId) {
        setIsDeployInfoWarning(true);
        setDeployInfo("wrong network - try again");
        setIsLoading(false);
        return;
      }

      setIsDeployInfoWarning(false);
      setDeployInfo("confirm tx in your wallet");
      const c = GetContractFileName();
      console.log("ContractFileName", c);
      var premi = d.premintable ? ToWei(d.premint) : null;
      var cappy = d.capped ? ToWei(d.maxSupply) : null;
      var owny = d.mintable || d.pausable ? addr : null;
      const deployResp = await deploy(
        d.name,
        d.symbol,
        c.contract.contract.abi,
        c.contract.contract.bytecode,
        owny,
        premi,
        cappy
      );
      const contract = deployResp.contract;
      if (contract) {
        setDeploySuccess(`✅ deployed to: ${contract.address}`);
        setIsDeployInfoWarning(false);
        setDeployInfo("success");
        try {
          setDeployInfo("adding Token to your wallet");
          const deployAddr = contract.address;
          const currentTime = new Date().toISOString();
          var saveC = {
            address: deployAddr,
            time: currentTime,
            properties: _.cloneDeep(d),
          };
          console.log("saveC", saveC);
          setUserDeployments([saveC, ...userDeployments]);
          console.log(deployAddr, d.symbol);
          await addTokenToWallet(deployAddr, d.symbol, 18, "");
          setDeployInfo("done");
          //setDeployPage("userDeployments");
          navigate("/deployed-tokens");
        } catch (error) {
          console.log("adding Token declined", error);
          setIsDeployInfoWarning(true);
          setDeployInfo("adding Token declined");
        }
      } else {
        setIsDeployInfoWarning(true);
        setDeployInfo(deployResp.errorMsg);
      }
    }

    setIsLoading(false); // Stop loading once everything is done
  };
  const GetContractFileName = () => {
    var keyWords = [];
    if (d.premintable) {
      keyWords.push("Premint");
    }
    if (d.mintable) {
      keyWords.push("Mintable");
    }
    if (d.capped) {
      keyWords.push("Capped");
    }
    if (d.burnable) {
      keyWords.push("Burnable");
    }
    if (d.pausable) {
      keyWords.push("Pausable");
    }
    if (d.permitable) {
      keyWords.push("Permit");
    }
    const contractRespons = getContract(keyWords);

    return contractRespons;
  };

  useEffect(() => {
    setDeploySuccess("");
    var deplInfo = "";
    var isDeployable = true;
    var d = deployInputs;
    if (!d.name) {
      deplInfo = "please enter a name";
      isDeployable = false;
    } else if (!d.symbol) {
      deplInfo = "missing symbol";
      isDeployable = false;
    } else if (d.premintable && Number(d.premint) == 0) {
      deplInfo = "missing premint quantity";
      isDeployable = false;
    } else if (!d.premintable && !d.mintable) {
      deplInfo = "must be mintable or have premint";
      isDeployable = false;
    } else if (
      d.mintable &&
      d.capped &&
      (Number(d.maxSupply) == 0 ||
        (d.premintable && Number(d.maxSupply) <= Number(d.premint)))
    ) {
      var min = d.premintable ? formatNumber(d.premint) : "0";
      deplInfo = `Limited Supply must be greater than Premint`;
      isDeployable = false;
    }
    var contractResponse = GetContractFileName();
    isDeployable = isDeployable && contractResponse.success;
    setDeployable(isDeployable);
    //if (contractResponse.success) {
    //console.log(contractResponse.contract);
    //}
    if (isDeployable) {
      deplInfo = ""; //`contract: ${contractResponse.contract.name}`;
    }
    setIsDeployInfoWarning(!isDeployable);
    setDeployInfo(deplInfo);
    var jsonStr = JSON.stringify(d);
    localStorage.setItem(localStorage_deploymentVariables, jsonStr);
  }, [deployInputs]);

  const d = deployInputs;

  return (
    <>
      <Helmet>
        <title>ERC20 Token Creation Form</title>
        <meta
          name="description"
          content="Effortless custom ERC20 token creation on multiple networks including Ethereum and it's L2 solutions without any coding. Click, create, and deploy your token now!"
        />
        <meta
          name="keywords"
          content="erc20 token development, create erc20 token, erc20 token creation, make erc20 token, l2 solutions, how to make a meme coin, how to create a meme coin"
        />
      </Helmet>
      {
        <div className="inputContainer">
          <div className="cardTitle">
            <h1>{title}</h1>
          </div>
          <div className="singleInputContainer">
            <div className="inputTitle">Name</div>
            <input
              disabled={isLoading}
              className="boxStyle2 inputField"
              ref={nameInputRef}
              type="text"
              placeholder="Library of Memes"
              maxLength={50}
              value={deployInputs.name}
              onChange={(e) => handleInputChange_Name(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, symbolInputRef)}
            />
          </div>
          <div className="singleInputContainer">
            <div className="inputTitle">Symbol</div>
            <input
              disabled={isLoading}
              className="inputField boxStyle2 "
              ref={symbolInputRef}
              type="text"
              placeholder="LOM"
              maxLength={5}
              value={deployInputs.symbol}
              onChange={(e) => handleInputChange_Symbol(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, premintInputRef)}
            />
          </div>
          <OptionalInputComponent
            disabled={isLoading}
            onCheckChanged={setPremintable}
            onValueChanged={setPremint}
            titleChecked="Premint"
            titleUnchecked="Premint"
            description=""
            maxInputLength="22"
            defaultChecked={deployInputs.premintable}
            defaultInputValue={deployInputs.premint}
          ></OptionalInputComponent>

          <div className="checkBoxContainer">
            <div className="checkBoxText">Mintable</div>
            <label className="switch">
              <input
                disabled={isLoading}
                type="checkbox"
                checked={deployInputs.mintable}
                onChange={() => {
                  const state = !deployInputs.mintable;
                  var cappState = !state ? false : deployInputs.capped;
                  setDeployInputs({
                    ...deployInputs,
                    mintable: state,
                    capped: cappState,
                  });
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>

          {deployInputs.mintable && (
            <div>
              <OptionalInputComponent
                disabled={isLoading}
                onCheckChanged={setCapped}
                onValueChanged={setMaxSupply}
                titleChecked="Limited Supply"
                titleUnchecked="Limited Supply"
                description=""
                maxInputLength="22"
                defaultChecked={deployInputs.capped}
                defaultInputValue={deployInputs.maxSupply}
                placeholder="10000000000"
              ></OptionalInputComponent>
            </div>
          )}
          <div className="checkBoxContainer">
            <div className="checkBoxText">Burnable</div>
            <label className="switch">
              <input
                disabled={isLoading}
                type="checkbox"
                checked={deployInputs.burnable}
                onChange={() => setBurnable(!deployInputs.burnable)}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="checkBoxContainer">
            <div className="checkBoxText">Pausable</div>
            <label className="switch">
              <input
                disabled={isLoading}
                type="checkbox"
                checked={deployInputs.pausable}
                onChange={() => setPausable(!deployInputs.pausable)}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="checkBoxContainer">
            <div className="checkBoxText">Permitable</div>
            <label className="switch">
              <input
                disabled={isLoading}
                type="checkbox"
                checked={deployInputs.permitable}
                onChange={() => setPermitable(!deployInputs.permitable)}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <div className="singleInputContainer">
            <div className="checkBoxText">Network</div>
            <DropdownComponent
              disabled={isLoading}
              selectedChain={deployInputs.selectedChainId}
              setSelectedChain={setSelectedChain}
            />
          </div>
          <div style={{ marginTop: "15px" }}></div>
          <div
            className="errorText"
            style={{ color: isDeployInfoWarning ? "#ffbb00" : "#55ffaa" }}
          >
            {deployInfo}
          </div>
          <button
            className="cardButton"
            ref={buttonRef}
            disabled={!deployable || isLoading}
            onClick={handleButtonClick}
          >
            {isLoading ? (
              <img
                src={iconLoading}
                alt="Loading..."
                style={{ height: "8px" }}
              />
            ) : (
              "Deploy"
            )}
          </button>
          <div style={{ marginTop: "10px" }}></div>
          <div className="priceText">{deploySuccess}</div>
          <div style={{ marginTop: "10px" }}></div>
          <div className="priceText">
            Price: <span className="textRedStrikeThrough">0.005 ETH</span>{" "}
            <span className="priceText" style={{ paddingLeft: "4px" }}>
              {" "}
              {"only 0.001 ETH 🔥"}
            </span>
          </div>
        </div>
      }
      <div className="pageButtonsContainer">
        <div
          className="backButton"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </div>
        <div
          className="wideButton"
          onClick={() => {
            navigate("/deployed-tokens");
            //setDeployPage("userDeployments");
          }}
        >
          Deployed Tokens
        </div>
      </div>
    </>
  );
};

export default DeploymentComponent;

import { useEffect, useState } from "react";

import CardContainerComponent from "./CardContainerComponent";
import { Helmet } from 'react-helmet';
import ImportExportComponent from "./ImportExportComponent";
import { useNavigate } from "react-router-dom";
import { useSelectableChains } from "../SelectableChainsContext"; // Ensure the path is correct

const UserDeploymentsComponent = ({ userDeployments, setUserDeployments }) => {
  /**
        {
            address: deployAddr,
            time: currentTime,
            properties: _.cloneDeep(d),
        }


        
  const defaultDeployInputs = {
    name: "",
    symbol: "",
    premint: "1000000000",
    maxSupply: "",
    //permit: "",
    premintable: true,
    mintable: false,
    burnable: true,
    pausable: false,
    permitable: true,
    capped: false,
    selectedChain: {
      id: "0x1",
      icon: iconEthereum,
      text: "Ethereum",
      nativeCurrency: { decimals: 18, symbol: "ETH" },
      rpcUrls: ["https://eth.llamarpc.com"],
      baseUrl:""
    },
  };

 */
  const navigate = useNavigate();
  const selectableChains = useSelectableChains();
  const yesNo = (yes) => (yes ? "Yes" : "No");

  const formatDateToCustomString = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed in JavaScript
    const day = d.getDate().toString().padStart(2, "0");
    const hours = d.getHours().toString().padStart(2, "0");
    const minutes = d.getMinutes().toString().padStart(2, "0");
    const seconds = d.getSeconds().toString().padStart(2, "0");
    // Calculate UTC offset
    const offset = -d.getTimezoneOffset();
    const offsetSign = offset >= 0 ? "+" : "-";
    const offsetHours = Math.floor(Math.abs(offset) / 60)
      .toString()
      .padStart(2, "0");
    const offsetMinutes = (Math.abs(offset) % 60).toString().padStart(2, "0");
    const utcString = `UTC${offsetSign}${offsetHours}:${offsetMinutes}`;

    return (
      <div>
        <span
          className="userDeploymentText"
          style={{ fontWeight: 400 }}
        >{`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`}</span>
        <span
          className="userDeploymentText"
          style={{ textAlign: "right", fontWeight: 200, color: "#bbbbbb" }}
        >{`${utcString}`}</span>
      </div>
    );
  };
  return (
    <div>
      {userDeployments && (
        <ul className="userDeployment-list">
          <Helmet>
          <title>Your Deployment Activities</title>
          <meta 
          name="description"
          content="Easily track the past activities of your ERC20 token deployments in one place."
          />
          <meta name="keywords" content="erc20 token development, create erc20 token, erc20 token creation, make erc20 token, l2 solutions, how to make a meme coin, how to create a meme coin, create and deploy erc20 token"/>
          </Helmet>
          <div
            className="cardTitle"
            style={{
              margin: "0px 0px",
              padding: "20px 0",
            }}
          >
            Deployed Tokens
          </div>
          {userDeployments.map((item) => {
            const props = item.properties;
            const chain = selectableChains[props.selectedChainId];
            return (
              <li
                key={item.address + item.properties.selectedChainId}
                className="userDeployment-item"
              >
                <div
                  style={{ fontWeight: 600 }}
                >{`${props.name} [${props.symbol}]`}</div>
                <div
                  className="userDeployment-chainItem"
                  style={{ justifyContent: "center" }}
                >
                  <img src={chain.icon} alt={props.name} className="icon" />
                  <span className="userDeploymentText">{`${chain.text}`}</span>
                </div>

                {formatDateToCustomString(item.time)}
                <div>
                  <a
                    className="userDeploymentText explorerLink"
                    href={chain.baseUrl + "address/" + item.address + "#code"}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#99aaff" }}
                  >{`View on ${chain.explorerName}`}</a>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      <ImportExportComponent
        userDeployments={userDeployments}
        setUserDeployments={setUserDeployments}
      ></ImportExportComponent>

      <div
        className="backButtonDeployments"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </div>
    </div>
  );
};

export default UserDeploymentsComponent;

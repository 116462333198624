import "../App.css";

import { ToEth, parseEth, parseGwei, parseGweiToEth } from "../ethersConnector";
import { useEffect, useState } from "react";

import { Helmet } from 'react-helmet';
import logo from "../assets/gasTransparent.png";

//        Meet the Standard token – your go-to solution for creating tokens with ease. It comes with all the standard features of the ERC-20 standard and offers extra option like supply limits. Craft your token your way and start your crypto adventure effortlessly!

const GastrackerComponent = ({ fees }) => {
  //const round = (number, decimals) => Number(`${Math.round(`${number}e${decimals}`)}e-${decimals}`);
  //const fromGwei = (gwei) => round(gwei / 1e9, 9).toString();

  return (
    <>
    <Helmet>
          <title>Ethereum Gas Tracker</title>
          <meta 
          name="description"
          content="Stay informed about fluctuating Ethereum gas prices to find the most cost-efficient moment for your transactions and avoid unnecessary spending."
          />
          <meta name="keywords" content="eth gas tracker, ethereum gas tracker, gwei to usd, ethereum gas fees calculator, eth gas calculator"/>
          </Helmet>
    <div style={{ width: "90%", margin:"0px auto" }}>
      <div className="firstContainer">
            
        <img src={logo} className="cardImage2" alt="Ethereum gas tracker"/>
        <h1 className="title">Gas Tracker</h1>
        <div id="container" className="container">
          <div id="1Box" className="boxStyle boxStyle1 boxColor">
            <div className="infoText">low</div>
            <div className="value">
              <div id="low" className="valueNumber">
                {fees.low}
              </div>
              <div className="valueGwei">gwei</div>
            </div>
            <div id="lowDollar" className="dollar">
              ({fees.lowUsd})
            </div>
          </div>
          <div id="2Box" className="boxStyle boxStyle1 boxColor">
            <div className="infoText">average</div>
            <div className="value">
              <div id="average" className="valueNumber">
                {fees.mid}
              </div>
              <div className="valueGwei">gwei</div>
            </div>
            <div id="averageDollar" className="dollar">
              ({fees.midUsd})
            </div>
          </div>
          <div id="3Box" className="boxStyle boxStyle1 boxColor">
            <div className="infoText">high</div>
            <div className="value">
              <div id="high" className="valueNumber">
                {fees.high}
              </div>
              <div className="valueGwei">gwei</div>
            </div>
            <div id="highDollar" className="dollar">
              ({fees.highUsd})
            </div>
          </div>
        </div>
      </div>

      <div id="footer" className="footerContainer">
        <div className="footerText">
          Disclaimer: All information provided is without guarantee. Data is sourced externally and refreshed every 60 seconds. While efforts are made to ensure
          accuracy, we cannot guarantee timeliness, completeness, or reliability. Users should independently verify data before making decisions. The platform
          and its affiliates are not liable for inaccuracies or actions taken based on the information. © 2024 - All Rights Reserved | Siempi AG
        </div>
      </div>
    </div>
    </>
  );
};

export default GastrackerComponent;

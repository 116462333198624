import "../App.css";

import {
  ToWei,
  parseEthToGwei,
  parseEthToWei,
  parseGwei,
  parseGweiToEth,
  parseGweiToWei,
  parseWeiToEth,
  parseWeiToGwei,
} from "../ethersConnector";
import { useEffect, useState } from "react";

import CopyButtonComponent from "./CopyButtonComponent";
import { Helmet } from 'react-helmet';
import logo from "../assets/converter.png";

//        Meet the Standard token – your go-to solution for creating tokens with ease. It comes with all the standard features of the ERC-20 standard and offers extra option like supply limits. Craft your token your way and start your crypto adventure effortlessly!

const ConverterComponent = ({ style }) => {
  const [ethValue, setEthValue] = useState(""); //1
  const [weiValue, setWeiValue] = useState(""); //1000000000000000000
  const [gweiValue, setGweiValue] = useState(""); //1000000000

  const onEthChanged = (value) => {
    //console.log("ethValue", value);
    if (value != "" && isNaN(Number(value))) {
      return;
    }
    if (value == "") {
      setEthValue(value);
      setWeiValue("");
      setGweiValue("");
      return;
    }
    var newValue = value;
    const valueSplit = value.split(".");
    if (valueSplit.length > 1) {
      const decimalDigits = valueSplit[1];
      if (decimalDigits.length > 18) {
        const newDecimals = decimalDigits.slice(0, 18);
        newValue = `${valueSplit[0]}.${newDecimals}`;
      }
    }
    const wei = parseEthToWei(newValue).replace(/\.0+$|(\.\d*[1-9])0+$/, "$1");
    const gwei = parseEthToGwei(newValue).replace(
      /\.0+$|(\.\d*[1-9])0+$/,
      "$1"
    );
    setEthValue(newValue);
    setWeiValue(wei);
    setGweiValue(gwei);
  };
  const onWeiChanged = (value) => {
    //console.log("weiValue", value);
    if (value != "" && isNaN(Number(value))) {
      return;
    }
    if (value == "") {
      setWeiValue(value);
      setEthValue("");
      setGweiValue("");
      return;
    }
    var newValue = value;
    const valueSplit = value.split(".");
    if (valueSplit.length > 1) {
      newValue = valueSplit[0];
    }
    const eth = parseWeiToEth(newValue).replace(/\.0+$|(\.\d*[1-9])0+$/, "$1");
    const gwei = parseWeiToGwei(newValue).replace(
      /\.0+$|(\.\d*[1-9])0+$/,
      "$1"
    );
    setWeiValue(newValue);
    setEthValue(eth);
    setGweiValue(gwei);
  };
  const onGweiChanged = (value) => {
    //console.log("gweiValue", value);
    if (value != "" && isNaN(Number(value))) {
      return;
    }
    if (value == "") {
      setGweiValue(value);
      setEthValue("");
      setWeiValue("");
      return;
    }
    var newValue = value;
    const valueSplit = value.split(".");
    if (valueSplit.length > 1) {
      const decimalDigits = valueSplit[1];
      if (decimalDigits.length > 9) {
        const newDecimals = decimalDigits.slice(0, 9);
        newValue = `${valueSplit[0]}.${newDecimals}`;
      }
    }
    const eth = parseGweiToEth(newValue).replace(/\.0+$|(\.\d*[1-9])0+$/, "$1");
    const wei = parseGweiToWei(newValue).replace(/\.0+$|(\.\d*[1-9])0+$/, "$1");
    setGweiValue(newValue);
    setEthValue(eth);
    setWeiValue(wei);
  };

  return (
    <>
    <Helmet>
          <title>Ethereum Unit Converter</title>
          <meta 
          name="description"
          content="Convert with ease between wei, gwei, and ether."
          />
          <meta name="keywords" content="gwei to eth, wei to eth , eth to wei, ether to wei, wei to ether, ethereum unit converter"/>
          </Helmet>
    <div style={{ width: "90%", margin:"0px auto" }}>
      <div className="secondContainer">
      
        <img src={logo} className="cardImage2" alt="Ethereum unit converter calculator" />

        <h1 className="title">Unit Converter</h1>
        <div id="box" className="box">
          <div id="text" className="text textConverter">
            wei
          </div>
          <input
            type="text"
            pattern="^-?[0-9]\d*\.?\d*$"
            className="boxStyle2 boxStyle2Converter"
            onChange={(e) => onWeiChanged(e.target.value)}
            value={weiValue}
            placeholder="1000000000000000000"
          />
          {weiValue && <CopyButtonComponent value={weiValue} id={1} />}
        </div>
        <div id="box" className="box">
          <div id="text" className="text textConverter">
            gwei
          </div>
          <input
            type="text"
            pattern="^-?[0-9]\d*\.?\d*$"
            className="boxStyle2 boxStyle2Converter"
            onChange={(e) => onGweiChanged(e.target.value)}
            value={gweiValue}
            placeholder="1000000000"
          />
          {gweiValue && <CopyButtonComponent value={gweiValue}  id={2}/>}
        </div>
        <div id="box" className="box">
          <div id="text" className="text textConverter">
            ether
          </div>
          <input
            type="text"
            pattern="^-?[0-9]\d*\.?\d*$"
            className="boxStyle2 boxStyle2Margin boxStyle2Converter"
            onChange={(e) => onEthChanged(e.target.value)}
            value={ethValue}
            placeholder="1"
          />
          {ethValue && <CopyButtonComponent value={ethValue} id={3}/>}
        </div>
      </div>
    </div>
    </>
  );
};

export default ConverterComponent;

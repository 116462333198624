import { useEffect, useState } from "react";
import logo from "../assets/ethLogo.png";
import "../App.css";
import { formatNumber } from "../ethersConnector";
//        Meet the Standard token – your go-to solution for creating tokens with ease. It comes with all the standard features of the ERC-20 standard and offers extra option like supply limits. Craft your token your way and start your crypto adventure effortlessly!

const OptionalInputComponent = ({
  style,
  onCheckChanged,
  onValueChanged,
  titleChecked,
  titleUnchecked,
  description,
  maxInputLength,
  defaultChecked = false,
  defaultInputValue = "",
  disabled = false,
  placeholder = "",
}) => {
  
  const [checked, setChecked] = useState(defaultChecked);
  const [value, setValue] = useState(defaultInputValue);

  const handleCheckChange = (event) => {
    const check = !checked;
    setChecked(check);
    onCheckChanged(check);
  };

  useEffect(()=>{

  },[value]);

  const handleInputChange = (value) => {
    var newValue = value;
    if (newValue != "" && isNaN(Number(newValue))) {
      return;
    }
    const valueSplit = value.split(".");
    if (valueSplit.length > 1) {
      return;
      //const decimalDigits = valueSplit[1];
      //if (decimalDigits.length > 18) {
      //  const newDecimals = decimalDigits.slice(0, 18);
      //  newValue = `${valueSplit[0]}.${newDecimals}`;
      //}
    }
    setValue(newValue);
    onValueChanged(newValue);
  };
  return (
    <div style={{ marginBottom:"-5px"}}>
      <div className="checkBoxContainer">
        <div className="checkBoxText">
        {checked?titleChecked:titleUnchecked}
        </div>
        <label className="switch">
          <input
            disabled={disabled}
            type="checkbox"
            checked={checked}
            onChange={handleCheckChange}
          />
          <span className="slider round"></span>
        </label>
      </div>

      <div >
        <div className="inputTitle"></div>
        { checked && (
        <input
          disabled={disabled}
          className="inputField boxStyle2"
          type="text" // Changed to 'text' to allow regex filtering
          maxLength={maxInputLength}
          value={value}
          onChange={(event)=>handleInputChange(event.target.value)}
          placeholder={placeholder}
        />
        )}
        <div className="formattedNumber" hidden={!checked}>{formatNumber(value)}</div>
        <div className="formattedNumber" hidden={!description}>{description}</div>
      </div>
    </div>
  );
};

export default OptionalInputComponent;

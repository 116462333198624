import "../App.css";

import logo from "../assets/ethLogo.png";
import { useState } from "react";

//        Meet the Standard token – your go-to solution for creating tokens with ease. It comes with all the standard features of the ERC-20 standard and offers extra option like supply limits. Craft your token your way and start your crypto adventure effortlessly!

const CardComponent = ({
  style,
  onClickFunction,
  title,
  endpoint,
  description,
  available,
}) => {
  return (
    <div className="cardContainer">
      <img src={logo} className="cardImage" alt="Ethereum" />
      
      <h1 className="cardTitle">{title}</h1>

      <div className="cardDescription">{description}</div>

      <button
        className="cardButton"
        disabled={!available}
        style={{
          cursor: available ? "pointer" : "default",
        }}
        onClick={() => onClickFunction(endpoint)}
      >
        {available ? "Create now" : "Coming soon..."}
      </button>
    </div>
  );
};

export default CardComponent;

import { useEffect, useState } from "react";

const ImportExportComponent = ({ userDeployments, setUserDeployments }) => {
  const [expand, setExpand] = useState(false);
  const [isHoveredExport, setIsHoveredExport] = useState(false);
  const [isHoveredImport, setIsHoveredImport] = useState(false);

  const handleMouseEnterExport = () => setIsHoveredExport(true);
  const handleMouseLeaveExport = () => setIsHoveredExport(false);
  const handleMouseEnterImport = () => setIsHoveredImport(true);
  const handleMouseLeaveImport = () => setIsHoveredImport(false);

  // Function to download JSON
  const downloadJson = () => {
    var jsonTxt = JSON.stringify(userDeployments, null, 2);
    if (jsonTxt) {
      const blob = new Blob([jsonTxt], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "myDeployments.json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      console.log("No data available to download.");
    }
  };

  // Function to handle file upload and JSON parsing
  const uploadJson = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const jsonTxt = e.target.result;
        try {
          const newDeployments = JSON.parse(jsonTxt);
          console.log("newDeployments", newDeployments);
          if (newDeployments) {
            // Create a map to hold unique deployments
            const uniqueDeployments = new Map();

            // Add existing deployments to the map
            userDeployments.forEach((d) =>
              uniqueDeployments.set(d.address + d.properties.selectedChainId, d)
            );

            // Add new deployments, skipping duplicates
            newDeployments.forEach((d) => {
              if (
                !uniqueDeployments.has(d.address + d.properties.selectedChainId)
              ) {
                uniqueDeployments.set(
                  d.address + d.properties.selectedChainId,
                  d
                );
              } //else {
              //console.log(`Duplicate found and skipped: ${d.address + d.properties.selectedChainId}`);
              //}
            });

            // Convert map values to an array and sort
            const fullList = Array.from(uniqueDeployments.values()).sort(
              (a, b) => {
                return new Date(b.time) - new Date(a.time);
              }
            );
            console.log("fullList", fullList);

            setUserDeployments(fullList);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div
      className="importExportContainer"
      style={{
        textAlign: "center",
        display: "flex",
        flexWrap: "wrap",
        columnGap: "2vmin",
        rowGap: "5px",
        justifyContent: "center",
        height: expand ? "auto" : "88px",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          margin: "15px",
          fontWeight: 400,
          fontSize: "14px",
        }}
      >
        Import / Export your Data
      </div>
      <label
        className={`smallButton ${isHoveredImport ? "hovered" : ""}`}
        onMouseEnter={handleMouseEnterImport}
        onMouseLeave={handleMouseLeaveImport}
        htmlFor="fileUpload"
        style={{ display: "block" }}
      >
        Import
      </label>
      <input
        type="file"
        onChange={uploadJson}
        style={{ display: "none" }}
        id="fileUpload"
        accept=".json"
      />
      <button
        className={`smallButton ${isHoveredExport ? "hovered" : ""}`}
        onClick={downloadJson}
        onMouseEnter={handleMouseEnterExport}
        onMouseLeave={handleMouseLeaveExport}
        disabled={userDeployments.length === 0}
      >
        Export
      </button>
      <button
        className="smallButton"
        style={{ width: "40px", marginLeft: "10%" }}
        onClick={() => setExpand(!expand)}
      >
        {expand ? "-" : "?"}
      </button>

      <div className="importExportText" style={{ marginTop: "10px" }}>
        We never store or even receive any data about your deployments.
        Everything is stored locally in your browser. If you clear
        your cache or use incognito mode, this data will be lost permanently.
      </div>
      <div className="importExportText">
        {
          <>
            <>By clicking</>
            <button
              className={`smallButton ${isHoveredExport ? "hovered" : ""}`}
              onClick={downloadJson}
              onMouseEnter={handleMouseEnterExport}
              onMouseLeave={handleMouseLeaveExport}
              disabled={userDeployments.length === 0}
              style={{ margin: "0px 3px" }}
            >
              Export
            </button>
            <>you can save your data as a JSON-Text-File on your device.</>
          </>
        }
      </div>
      <div className="importExportText" style={{ marginBottom: "20px" }}>
        {
          <>
            <>If you already have such a file, you can</>
            <label
              className={`smallButton ${isHoveredImport ? "hovered" : ""}`}
              onMouseEnter={handleMouseEnterImport}
              onMouseLeave={handleMouseLeaveImport}
              htmlFor="fileUpload"
              style={{ display: "inline-block", margin: "0px 3px" }}
            >
              Import
            </label>
            <>it.</>
          </>
        }
      </div>
    </div>
  );
};

export default ImportExportComponent;

import React, { useState , useEffect} from "react";
import { useSelectableChains } from '../SelectableChainsContext'; // Ensure the path is correct



// DropdownMenu component
const DropdownComponent = ({ selectedChain, setSelectedChain, disabled }) => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the dropdown visibility

  // Sample data for menu items with image icons
  const selectableChains = useSelectableChains();
const menuItems = Object.values(selectableChains).map(item => item);

  // Function to toggle dropdown visibility
  const toggleDropdown = () => setIsOpen(!isOpen && !disabled);

const handleSelection = (item) => {
    //console.log('Before update:', selectedChain); // Check current state before update
    setSelectedChain(item);  // Update the state
    //console.log('After update:', selectedChain); // Check the item being set
    setIsOpen(false); // Close the dropdown
  };

    // Click outside handler
    useEffect(() => {
        const handleClickOutside = (event) => {
          // This references the dropdown element
          const dropdownElement = document.querySelector('.dropdown');
    
          // If the click is outside the dropdown, close it
          if (dropdownElement && !dropdownElement.contains(event.target)) {
            setIsOpen(false);
          }
        };
    
        // Attach the listener
        document.addEventListener('mousedown', handleClickOutside);
    
        // Cleanup the listener on component unmount
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []); // Empty dependency array means this effect runs once on mount and once on unmount
    
  
  return (
    <div className="dropdown">
      {isOpen && (
        <ul className="dropdown-list">
          {menuItems.map((item) => (
            
            <li
              key={item.id}
              className="dropdown-item"
              onClick={() => handleSelection(item)}
            >
              <img src={item.icon} alt={item.text} className="icon" />
              <span className="dropdownText">{item.text}</span>
            </li>
          ))}
        </ul>
      )}
      <button onClick={toggleDropdown} className="dropdown-button">
        <img src={selectableChains[selectedChain].icon} alt={selectableChains[selectedChain].text} className="icon" />
        <span className="dropdownButtonText">{selectableChains[selectedChain].text}</span>
      </button>
    </div>
  );
};

export default DropdownComponent;

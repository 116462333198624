import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const MenuComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Function to change the current page
  const changePage = (name) => {
    navigate("/" + name);
  };

  // Helper function to determine if a menu item is active
  const isActive = (menuName) => {
    // Check if the current pathname corresponds to the menuName
    return location.pathname === "/" + menuName || (location.pathname === "/" && menuName === "main");
  };

  return (
    <div>
      <div className="menu">
        <div
          className={`menuButton ${isActive("main") || isActive("create-erc20")||isActive("deployed-tokens") ? "menuButtonActive" : ""}`}
          style={{ borderLeft: "none" }}
          onClick={() => changePage("main")}
        >
          Token Creator
        </div>
        <div
          className={`menuButton ${isActive("gastracker") ? "menuButtonActive" : ""}`}
          onClick={() => changePage("gastracker")}
        >
          Gas Tracker
        </div>
        <div
          className={`menuButton ${isActive("ethereum-unit-converter") ? "menuButtonActive" : ""}`}
          onClick={() => changePage("ethereum-unit-converter")}
        >
          Unit Converter
        </div>
      </div>

      <div
        className="logo"
        onClick={() => navigate("/")}
      ></div>
    </div>
  );
};

export default MenuComponent;

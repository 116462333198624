import React, { useState } from "react";
import "../App.css";
import iconCopy from "../assets/copy2.svg";
import iconCheck from "../assets/check2.svg";

// Reusable copy button component
const CopyButtonComponent = ({ value, id }) => {
  const [isActive, setIsActive] = useState(false);

  const copyJSONToClipboard = async () => {
    const txt = value.toString();
    const blob = new Blob([txt], { type: "text/plain" });
    try {
      await navigator.clipboard.write([
        new ClipboardItem({ "text/plain": blob }),
      ]);
      triggerActiveState(); // Trigger the visual effect
    } catch (error) {
      console.error("Error copying data to clipboard:", error);
      copyJSONToClipboardFallback();
    }
  };

  const copyJSONToClipboardFallback = () => {
    const txt = String(value);
    const textarea = document.createElement("textarea");
    textarea.value = txt;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    triggerActiveState(); // Trigger the visual effect even on fallback
  };

  const triggerActiveState = () => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 700);
  };

  return (
    <button
      id={"copyButton" + id}
      className={`copyButton ${isActive ? "active" : ""}`}
      onClick={copyJSONToClipboard}
      style={{
        backgroundImage: `url(${isActive ? iconCheck : iconCopy})`, // Correct usage of url()
        backgroundSize: `${isActive ? "20px 20px" : "20px 20px"}`, // Set the background image size to 35px by 35px
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    ></button>
  );
};

export default CopyButtonComponent;

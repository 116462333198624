import "../App.css";

import CardComponent from "./CardComponent";
import logo from "../assets/logo.png";
import { useState } from "react";

//        Meet the Standard token – your go-to solution for creating tokens with ease. It comes with all the standard features of the ERC-20 standard and offers extra option like supply limits. Craft your token your way and start your crypto adventure effortlessly!

const CardContainerComponent = ({ style, onClickFunction }) => {
  return (
    <div className="outerCardContainer">
      <img src={logo} className="logo" alt="Ethtimate" />
      <CardComponent
        title="Create ERC20"
        endpoint="create-erc20"
        description="Your custom ERC20 Token, with a few clicks and zero lines of code."
        onClickFunction={onClickFunction}
        available={true}
      />
      <CardComponent
        title="Create ERC721"
        endpoint="createERC721"
        description="Your custom ERC721 Token, with a few clicks and zero lines of code."
        onClickFunction={onClickFunction}
        available={false}
      />
    </div>
  );
};

export default CardContainerComponent;

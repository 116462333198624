import * as ethers from "ethers";
import { testbytecode } from "./abis/testbytecode";
//import { factoryAbi } from "./abis/factoryAbi";
//import { ifactoryAbi } from "./abis/ifactoryAbi";
//import { GenericFactory } from "./abis/GenericFactory";
//import { ERC20Mintable } from "./abis/templates/ERC20Mintable";
//const factoryAddress = "0x035a7EA3e95714d9013eDe679b52C67E143d3071";

let connection = { connected: false };
const price = 0.001;

export const connect = async (forceReload = false) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum,"any");
    connection.accounts = await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    connection.signer = signer;
    //connection.instance = new ethers.Contract(
    //  factoryAddress,
    //  GenericFactory.abi,
    //  signer
    //);
    connection.connected = true;
    return true;
  } catch (error) {
    return false;
  }
};

export const getAddress = async () => {
  return await connection.signer.getAddress();
};

export const deployErc20 = async (name, symbol, supply, owner) => {
  console.log(`Owner: ${owner}`);
  if (!connection.connected) {
    if (!connect()) {
      console.log(`Wallet was not connected`);
      return;
    }
  }
  try {
    // Assuming `connection.instance` is an instance of a smart contract with a function to create an ERC20 token.
    let txResponse = await connection.instance.createERC20Custom(
      name,
      symbol,
      supply,
      owner
    );
    console.log("Transaction submitted! Hash:", txResponse.hash);

    // Wait for 2 confirmations. The 'wait' method will resolve after the specified number of confirmations.
    let receipt = await txResponse.wait(1);
    console.log("Transaction confirmed! Block number:", receipt.blockNumber);

    // Find the OwnershipTransferred event
    const ownershipTransferredEvent = receipt.events.find(
      (event) => event.event === "OwnershipTransferred"
    );

    // Extract the contract address from the 'address' field of the event
    const address = ownershipTransferredEvent
      ? ownershipTransferredEvent.address
      : null;

    /** 
    connection.deployment = new ethers.Contract(
      address,
      erc20CustomAbi.abi,
      connection.signer
    );
      */
    return address;
  } catch (error) {
    console.error("deployErc20-error: " + error);
    throw error;
  }
};

export const getName = async () => {
  if (!connection.connected) {
    if (!connect()) {
      return;
    }
  }
  try {
    // Assuming `connection.instance` is an instance of a smart contract with a function to create an ERC20 token.
    let name = await connection.deployment.name();
    console.log("Name:", name);
  } catch (error) {
    console.error("getName-error: " + error);
    throw error;
  }
};
export const getSymbol = async () => {
  if (!connection.connected) {
    if (!connect()) {
      return;
    }
  }
  try {
    // Assuming `connection.instance` is an instance of a smart contract with a function to create an ERC20 token.
    let symbol = await connection.deployment.symbol();
    console.log("Symbol:", symbol);
  } catch (error) {
    console.error("getSymbol-error: " + error);
    throw error;
  }
};
export const getDecimals = async () => {
  if (!connection.connected) {
    if (!connect()) {
      return;
    }
  }
  try {
    // Assuming `connection.instance` is an instance of a smart contract with a function to create an ERC20 token.
    let decimals = await connection.deployment.decimals();
    console.log("Decimals:", decimals);
  } catch (error) {
    console.error("getDecimals-error: " + error);
    throw error;
  }
};
export const addTokenToWallet = async (address, symbol, decimals, image) => {
  return await window.ethereum.request({
    method: "wallet_watchAsset",
    params: {
      type: "ERC20", // Initially only supports ERC20, but eventually more!
      options: {
        address: address, // The address that the token is at.
        symbol: symbol, // A ticker symbol or shorthand, up to 5 chars.
        decimals: decimals, // The number of decimals in the token
        image: image, // A string url of the token logo
      },
    },
  });
};
export const ToEth = (val) => {
  return ethers.utils.formatEther(val.toString());
};
export const ToWei = (amount) => {
  return ethers.utils.parseUnits(amount.toString(), "ether");
};
export const parseGweiToEth = (value) => {
  const wei = ethers.utils.parseUnits(value.toString(), "gwei");
  const eth = ethers.utils.formatEther(wei);
  return eth;
};
export const parseEthToGwei = (value) => {
  const wei = ethers.utils.parseUnits(value.toString(), "ether");
  const gwei = ethers.utils.formatUnits(wei, "gwei");
  return gwei;
};
export const parseEthToWei = (value) => {
  const wei = ethers.utils.parseUnits(value.toString(), "ether");
  return wei.toString();
};
export const parseWeiToEth = (value) => {
  const eth = ethers.utils.formatEther(value);
  return eth;
};
export const parseWeiToGwei = (value) => {
  const gwei = ethers.utils.formatUnits(value, "gwei");
  return gwei;
};
export const parseGweiToWei = (value) => {
  const wei = ethers.utils.parseUnits(value.toString(), "gwei");
  return wei.toString();
};
export const switchNetwork = async (chainId) => {
  return await window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: chainId }],
  });
};
export const addChainToMetaMask = async ({
  chainId,
  chainName,
  nativeCurrency,
  rpcUrls,
}) => {
  return await window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: chainId,
        chainName: chainName,
        nativeCurrency: nativeCurrency,
        rpcUrls: rpcUrls,
      },
    ],
  });
};
export const getUserChain = async () => {
  return await window.ethereum.request({ method: "eth_chainId" });
};
export const deploy = async (
  name,
  symbol,
  abi,
  bytecode,
  owner = null,
  premint = null,
  cap = null
) => {
  //if (!connection.connected) {
    if (!connect()) {
      return {contract: null, errorMsg:"wallet not connected"};
    }
  //}
  var errorMsg = "";
  var response = null;
  try {
    console.log("start deploying");
    const factory = new ethers.ContractFactory(
      abi,
      bytecode,
      connection.signer
    );
    const valueToSend = ToWei(price);
    if (owner) {
      //console.log("has owner");
      if (cap && premint) {
        response = await factory.deploy(name, symbol, owner, premint, cap, {
          value: valueToSend,
        });
      } else if (cap) {
        response = await factory.deploy(name, symbol, owner, cap, {
          value: valueToSend,
        });
      } else if (premint) {
        response = await factory.deploy(name, symbol, owner, premint, {
          value: valueToSend,
        });
      } else {
        response = await factory.deploy(name, symbol, owner, {
          value: valueToSend,
        });
      }
      //console.log("has owner worked");
    } else {
      //console.log("no owner");
      if (cap && premint) {
        response = await factory.deploy(name, symbol, premint, cap, {
          value: valueToSend,
        });
      } else if (cap) {
        response = await factory.deploy(name, symbol, cap, {
          value: valueToSend,
        });
      } else if (premint) {
        response = await factory.deploy(name, symbol, premint, {
          value: valueToSend,
        });
      } else {
        response = await factory.deploy(name, symbol, { value: valueToSend });
      }
      //console.log("no owner worked");
    }
    //await response.wait(3);
  } catch (error) {
    if(error){
      if(error.message && error.data){
        if(error.data.message){
          errorMsg=error.data.message;
        }
      }else if(error.message){
        errorMsg=error.message;
      }else{
        errorMsg="unknown error";
      }
      if(errorMsg.includes("insufficient")){
        errorMsg = "insufficient balance"
      }
      if(errorMsg.includes("rejected")){
        errorMsg = "tx rejected"
      }
    }else{
      errorMsg="unknown error";
    }
    response = null;
    console.log("deploy-error",error);
  }
  return {contract: response, errorMsg:errorMsg};
  //return response;
};
export const getEncodedParams = async (args, types, bytecode) => {
  const encodedArgs = ethers.utils.defaultAbiCoder.encode(types, args);
  const bytecodeWithParams = bytecode + encodedArgs.substring(2);
  return bytecodeWithParams;
};
export const deployExpensive = async (
  name,
  symbol,
  abi,
  bytecode,
  owner = null,
  premint = null,
  cap = null
) => {
  if (!connection.connected) {
    if (!connect()) {
      return;
    }
  }
  var response = null;
  var price = ToWei(0.0011);
  try {
    if (owner) {
      if (cap && premint) {
        var bytecodeWithParams = getEncodedParams(
          [name, symbol, owner, premint, cap],
          ["string", "string", "address", "uint256", "uint256"],
          bytecode
        );
        var tx = await connection.instance.deployFromBytecode(
          bytecodeWithParams,
          { value: price }
        );
        response = await tx.wait(1);
      } else if (cap) {
        var bytecodeWithParams = getEncodedParams(
          [name, symbol, owner, cap],
          ["string", "string", "address", "uint256"],
          bytecode
        );
        var tx = await connection.instance.deployFromBytecode(
          bytecodeWithParams,
          { value: price }
        );
        response = await tx.wait(1);
      } else if (premint) {
        var bytecodeWithParams = getEncodedParams(
          [name, symbol, owner, premint],
          ["string", "string", "address", "uint256"],
          bytecode
        );
        var tx = await connection.instance.deployFromBytecode(
          bytecodeWithParams,
          { value: price }
        );
        response = await tx.wait(1);
      } else {
        var bytecodeWithParams = getEncodedParams(
          [name, symbol, owner],
          ["string", "string", "address"],
          bytecode
        );
        var tx = await connection.instance.deployFromBytecode(
          bytecodeWithParams,
          { value: price }
        );
        response = await tx.wait(1);
      }
    } else {
      if (cap && premint) {
        var bytecodeWithParams = getEncodedParams(
          [name, symbol, premint, cap],
          ["string", "string", "uint256", "uint256"],
          bytecode
        );
        var tx = await connection.instance.deployFromBytecode(
          bytecodeWithParams,
          { value: price }
        );
        response = await tx.wait(1);
      } else if (cap) {
        var bytecodeWithParams = getEncodedParams(
          [name, symbol, cap],
          ["string", "string", "uint256"],
          bytecode
        );
        var tx = await connection.instance.deployFromBytecode(
          bytecodeWithParams,
          { value: price }
        );
        response = await tx.wait(1);
      } else if (premint) {
        //console.log("===> Premint Contract <===");
        //console.log(testbytecode.withparams);
        var bytecodeWithParams = getEncodedParams(
          [name, symbol, premint],
          ["string", "string", "uint256"],
          bytecode
        );
        var tx = await connection.instance.deployFromBytecode(
          testbytecode.withparams,
          { value: price }
        );
        response = await tx.wait(1);
      } else {
        var bytecodeWithParams = getEncodedParams(
          [name, symbol],
          ["string", "string"],
          bytecode
        );
        var tx = await connection.instance.deployFromBytecode(
          bytecodeWithParams,
          { value: price }
        );
        response = await tx.wait(1);
      }
    }
  } catch (error) {
    console.log("deployExp-error",error);
  }
  return response;
};
const isEthAddress = (address) => {
  return /^(0x)?[0-9a-f]{40}$/i.test(address);
};

export const formatNumber = (number) => {
  var flo = parseFloat(number);
  if(flo && flo <1 && flo > 0){
    return "< 1 Token";
  }
  const num = parseInt(number);
  if (!num) {
    return "0 Tokens";
  }
  var splits = number.split('.')
  var intStr = splits[0];
  var isExact = splits.length == 1;
  if (num < 1000000) {
    var ca =isExact? "":"~ ";
    var s = num === 1 ? "" : "s";
    return ca + new Intl.NumberFormat().format(num) + " Token" + s;
  }
  const units = [
    "",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
    "decillion",
  ];
  var subStr = intStr.slice(0,intStr.length-3);
  var l = Math.floor((subStr.length - 1) / 3);
  var end = intStr.length-3 - l *3;
  var lead = parseInt(intStr.slice(0,end));
  var fullRemainderStr = number.slice(end);
  isExact = isExact && fullRemainderStr.split('').every((x)=>x==='0');
  var remainder = parseInt(intStr.slice(end,end+3));
  if(remainder>=500){
    lead++;
  }
  var ca =isExact? "":"~ ";
  return `${ca}${lead} ${units[l]} Tokens`;

  
/**  OLD
  var mult = 1000000;
  let value = num / mult;
  let index = 0;
  while (value >= 1000 && index < units.length - 1) {
    value /= 1000;
    mult *= 1000;
    index++;
  }
  var rounded = Math.round(value);
  var ca = "";
  if (rounded * mult != num) {
    ca = "~ ";
  }
  return `${ca}${rounded} ${units[index]} Tokens`;
  */
};

import { ERC20Mintable } from "./templates/ERC20Mintable.js";
import { ERC20CappedMintable } from "./templates/ERC20CappedMintable.js";
import { ERC20CappedMintableBurnable } from "./templates/ERC20CappedMintableBurnable.js";
import { ERC20CappedMintableBurnablePausable } from "./templates/ERC20CappedMintableBurnablePausable.js";
import { ERC20CappedMintableBurnablePausablePermit } from "./templates/ERC20CappedMintableBurnablePausablePermit.js";
import { ERC20CappedMintableBurnablePermit } from "./templates/ERC20CappedMintableBurnablePermit.js";
import { ERC20CappedMintablePausable } from "./templates/ERC20CappedMintablePausable.js";
import { ERC20CappedMintablePausablePermit } from "./templates/ERC20CappedMintablePausablePermit.js";
import { ERC20CappedMintablePermit } from "./templates/ERC20CappedMintablePermit.js";
import { ERC20CappedMintablePremint } from "./templates/ERC20CappedMintablePremint.js";
import { ERC20CappedMintablePremintBurnable } from "./templates/ERC20CappedMintablePremintBurnable.js";
import { ERC20CappedMintablePremintBurnablePausable } from "./templates/ERC20CappedMintablePremintBurnablePausable.js";
import { ERC20CappedMintablePremintBurnablePausablePermit } from "./templates/ERC20CappedMintablePremintBurnablePausablePermit.js";
import { ERC20CappedMintablePremintBurnablePermit } from "./templates/ERC20CappedMintablePremintBurnablePermit.js";
import { ERC20CappedMintablePremintPausable } from "./templates/ERC20CappedMintablePremintPausable.js";
import { ERC20CappedMintablePremintPausablePermit } from "./templates/ERC20CappedMintablePremintPausablePermit.js";
import { ERC20CappedMintablePremintPermit } from "./templates/ERC20CappedMintablePremintPermit.js";
import { ERC20MintableBurnable } from "./templates/ERC20MintableBurnable.js";
import { ERC20MintableBurnablePausable } from "./templates/ERC20MintableBurnablePausable.js";
import { ERC20MintableBurnablePausablePermit } from "./templates/ERC20MintableBurnablePausablePermit.js";
import { ERC20MintableBurnablePermit } from "./templates/ERC20MintableBurnablePermit.js";
import { ERC20MintablePausable } from "./templates/ERC20MintablePausable.js";
import { ERC20MintablePausablePermit } from "./templates/ERC20MintablePausablePermit.js";
import { ERC20MintablePermit } from "./templates/ERC20MintablePermit.js";
import { ERC20MintablePremint } from "./templates/ERC20MintablePremint.js";
import { ERC20MintablePremintBurnable } from "./templates/ERC20MintablePremintBurnable.js";
import { ERC20MintablePremintBurnablePausable } from "./templates/ERC20MintablePremintBurnablePausable.js";
import { ERC20MintablePremintBurnablePausablePermit } from "./templates/ERC20MintablePremintBurnablePausablePermit.js";
import { ERC20MintablePremintBurnablePermit } from "./templates/ERC20MintablePremintBurnablePermit.js";
import { ERC20MintablePremintPausable } from "./templates/ERC20MintablePremintPausable.js";
import { ERC20MintablePremintPausablePermit } from "./templates/ERC20MintablePremintPausablePermit.js";
import { ERC20MintablePremintPermit } from "./templates/ERC20MintablePremintPermit.js";
import { ERC20Premint } from "./templates/ERC20Premint.js";
import { ERC20PremintBurnable } from "./templates/ERC20PremintBurnable.js";
import { ERC20PremintBurnablePausable } from "./templates/ERC20PremintBurnablePausable.js";
import { ERC20PremintBurnablePausablePermit } from "./templates/ERC20PremintBurnablePausablePermit.js";
import { ERC20PremintBurnablePermit } from "./templates/ERC20PremintBurnablePermit.js";
import { ERC20PremintPausable } from "./templates/ERC20PremintPausable.js";
import { ERC20PremintPausablePermit } from "./templates/ERC20PremintPausablePermit.js";
import { ERC20PremintPermit } from "./templates/ERC20PremintPermit.js";

const contracts = [
    { name: "ERC20Mintable", contract: ERC20Mintable },
    { name: "ERC20CappedMintable", contract: ERC20CappedMintable },
    { name: "ERC20CappedMintableBurnable", contract: ERC20CappedMintableBurnable },
    { name: "ERC20CappedMintableBurnablePausable", contract: ERC20CappedMintableBurnablePausable },
    { name: "ERC20CappedMintableBurnablePausablePermit", contract: ERC20CappedMintableBurnablePausablePermit },
    { name: "ERC20CappedMintableBurnablePermit", contract: ERC20CappedMintableBurnablePermit },
    { name: "ERC20CappedMintablePausable", contract: ERC20CappedMintablePausable },
    { name: "ERC20CappedMintablePausablePermit", contract: ERC20CappedMintablePausablePermit },
    { name: "ERC20CappedMintablePermit", contract: ERC20CappedMintablePermit },
    { name: "ERC20CappedMintablePremint", contract: ERC20CappedMintablePremint },
    { name: "ERC20CappedMintablePremintBurnable", contract: ERC20CappedMintablePremintBurnable },
    { name: "ERC20CappedMintablePremintBurnablePausable", contract: ERC20CappedMintablePremintBurnablePausable },
    { name: "ERC20CappedMintablePremintBurnablePausablePermit", contract: ERC20CappedMintablePremintBurnablePausablePermit },
    { name: "ERC20CappedMintablePremintBurnablePermit", contract: ERC20CappedMintablePremintBurnablePermit },
    { name: "ERC20CappedMintablePremintPausable", contract: ERC20CappedMintablePremintPausable },
    { name: "ERC20CappedMintablePremintPausablePermit", contract: ERC20CappedMintablePremintPausablePermit },
    { name: "ERC20CappedMintablePremintPermit", contract: ERC20CappedMintablePremintPermit },
    { name: "ERC20MintableBurnable", contract: ERC20MintableBurnable },
    { name: "ERC20MintableBurnablePausable", contract: ERC20MintableBurnablePausable },
    { name: "ERC20MintableBurnablePausablePermit", contract: ERC20MintableBurnablePausablePermit },
    { name: "ERC20MintableBurnablePermit", contract: ERC20MintableBurnablePermit },
    { name: "ERC20MintablePausable", contract: ERC20MintablePausable },
    { name: "ERC20MintablePausablePermit", contract: ERC20MintablePausablePermit },
    { name: "ERC20MintablePermit", contract: ERC20MintablePermit },
    { name: "ERC20MintablePremint", contract: ERC20MintablePremint },
    { name: "ERC20MintablePremintBurnable", contract: ERC20MintablePremintBurnable },
    { name: "ERC20MintablePremintBurnablePausable", contract: ERC20MintablePremintBurnablePausable },
    { name: "ERC20MintablePremintBurnablePausablePermit", contract: ERC20MintablePremintBurnablePausablePermit },
    { name: "ERC20MintablePremintBurnablePermit", contract: ERC20MintablePremintBurnablePermit },
    { name: "ERC20MintablePremintPausable", contract: ERC20MintablePremintPausable },
    { name: "ERC20MintablePremintPausablePermit", contract: ERC20MintablePremintPausablePermit },
    { name: "ERC20MintablePremintPermit", contract: ERC20MintablePremintPermit },
    { name: "ERC20Premint", contract: ERC20Premint },
    { name: "ERC20PremintBurnable", contract: ERC20PremintBurnable },
    { name: "ERC20PremintBurnablePausable", contract: ERC20PremintBurnablePausable },
    { name: "ERC20PremintBurnablePausablePermit", contract: ERC20PremintBurnablePausablePermit },
    { name: "ERC20PremintBurnablePermit", contract: ERC20PremintBurnablePermit },
    { name: "ERC20PremintPausable", contract: ERC20PremintPausable },
    { name: "ERC20PremintPausablePermit", contract: ERC20PremintPausablePermit },
    { name: "ERC20PremintPermit", contract: ERC20PremintPermit }
];


//console.log(contracts);


export const getContract = (keyWords) => {
    let result = { success: false, contract: null };

    // Iterate over all contracts
    for (let index = 0; index < contracts.length; index++) {
        const contract = contracts[index];
        let success = keyWords.length > 0;  // Assume success if there are keywords to check

        for (let i = 0; i < keyWords.length; i++) {
            const key = keyWords[i].toLowerCase();  // Case-insensitive comparison
            if (!contract.name.toLowerCase().includes(key)) {  // Case-insensitive comparison
                success = false;
                break;
            }
        }

        if (success) {
            var totalount = keyWords.reduce((sum, currentString) => sum + currentString.length, 0)+5;
            if(contract.name.length!==totalount){
                //console.log(`${contract.name} does not match ${keyWords}`);
                continue;
            }
            else{
                result = { success: true, contract: contract };
                break;
            }
        }
    }

    // Log failure only if no match is found
    /**
    if (!result.success) {
        if(keyWords.length == 0){
            console.log(`No Contract found because no Options are selected`);
        }
        else{
            console.log(`No Contract found for: ${keyWords}`);
        }
    }
    else{
        //console.log(`Contract found for ${keyWords}`);
    }
 */
    return result;
}


export const getContract2 = (keyWords)=>{
    var result = {success:false};
    for (let index = 0; index < contracts.length; index++) {
        const contract = contracts[index];
        var success = keyWords.length > 0;
        for (let i = 0; i < keyWords.length; i++) {
            const key = keyWords[i];
            if(!contract.name.includes(key)){
                success = false;
                break;
            }
        }
        if(success){
            result.success = true;
            result.contract = contract;
        }
        
    }
    console.log(`No Contract found for ${keyWords}`);
    return result;
}
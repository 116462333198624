import React, { createContext, useContext } from 'react';
import iconArbitrum from "./assets/chainLogos/arbitrum.svg";
import iconBase from "./assets/chainLogos/base.svg";
import iconEthereum from "./assets/chainLogos/ethereum.svg";
import iconOptimism from "./assets/chainLogos/optimism.svg";
import iconPolygon from "./assets/chainLogos/polygon.svg";
import iconAvalanche from "./assets/chainLogos/avalanche.svg";
import iconBsc from "./assets/chainLogos/bsc.svg";
import iconArbSepolia from "./assets/chainLogos/arbitrum_sepolia.svg";

// Sample data for selectable chain items with image icons
//bsc ava poly
const selectableChainsData = [
    {
      id: "0x1",
      icon: iconEthereum,
      text: "Ethereum",
      nativeCurrency: { decimals: 18, symbol: "ETH" },
      rpcUrls: ["https://eth.llamarpc.com"],
      baseUrl: "https://etherscan.io/",
      explorerName: "Etherscan",
    },
    {
      id: "0xa4b1",
      icon: iconArbitrum,
      text: "Arbitrum",
      nativeCurrency: { decimals: 18, symbol: "ETH" },
      rpcUrls: ["https://1rpc.io/arb"],
      baseUrl: "https://arbiscan.io/",
      explorerName: "Arbiscan",
    },
    {
      id: "0x2105",
      icon: iconBase,
      text: "Base",
      nativeCurrency: { decimals: 18, symbol: "ETH" },
      rpcUrls: ["https://base.llamarpc.com"],
      baseUrl: "https://basescan.org/",
      explorerName: "Basescan",
    },
    {
      id: "0xa",
      icon: iconOptimism,
      text: "Optimism",
      nativeCurrency: { decimals: 18, symbol: "ETH" },
      rpcUrls: ["https://optimism.llamarpc.com"],
      baseUrl: "https://optimistic.etherscan.io/",
      explorerName: "Optimistic Etherscan",
    },
    {
      id: "0xaa36a7",
      icon: iconArbSepolia,
      text: "Sepolia",
      nativeCurrency: { decimals: 18, symbol: "ETH" },
      rpcUrls: ["https://ethereum-sepolia-rpc.publicnode.com"],
      baseUrl: "https://sepolia.etherscan.io/",
      explorerName: "Sepolia Etherscan",
    },
    /**{
      id: "0x89",
      icon: iconPolygon,
      text: "Polygon",
      nativeCurrency: { decimals: 18, symbol: "MATIC" },
      rpcUrls: ["https://polygon-bor-rpc.publicnode.com"],
      baseUrl: "https://polygonscan.com/",
      explorerName: "Basescan",
    },
    {
      id: "0xa86a",
      icon: iconAvalanche,
      text: "Avalanche",
      nativeCurrency: { decimals: 18, symbol: "AVAX" },
      rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
      baseUrl: "https://avascan.info/",
      explorerName: "Avascan",
    },
    {
      id: "0x38",
      icon: iconBsc,
      text: "BSC",
      nativeCurrency: { decimals: 18, symbol: "BNB" },
      rpcUrls: ["https://binance.llamarpc.com"],
      baseUrl: "https://bscscan.com/",
      explorerName: "BSCscan",
    },*/
    {
      id: "0x66eee",
      icon: iconArbSepolia,
      text: "Arbitrum Sepolia",
      nativeCurrency: { decimals: 18, symbol: "ETH" },
      rpcUrls: ["https://endpoints.omniatech.io/v1/arbitrum/sepolia/public"],
      baseUrl: "https://sepolia.arbiscan.io/",
      explorerName: "Sepolia Arbiscan",
    },
];

// Create the context
const SelectableChainsContext = createContext({});

// Exportable hook to use the context
export function useSelectableChains() {
  return useContext(SelectableChainsContext);
}

// Provider component
export const SelectableChainsProvider = ({ children }) => {
  // Convert array to dictionary
  const selectableChains = selectableChainsData.reduce((acc, chain) => {
    acc[chain.id] = chain;
    return acc;
  }, {});

  return (
    <SelectableChainsContext.Provider value={selectableChains}>
      {children}
    </SelectableChainsContext.Provider>
  );
};

/* global dataLayer */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Safe wrapper for the gtag function
const safeGtag = (...args) => {
  if (typeof window.gtag === 'function') {
    window.gtag(...args);
  } else {
    console.warn('gtag not initialized');
  }
};

const GoogleAnalytics = ({ trackingId }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', trackingId);
    };

    return () => {
      document.head.removeChild(script);
    };
  }, [trackingId]);

  return null;
};

const TrackPageViews = ({ trackingId }) => {
  const location = useLocation();

  useEffect(() => {
    const trackPage = (pathname) => {
      safeGtag('config', trackingId, {
        page_path: pathname,
      });
    };

    trackPage(location.pathname + location.search);
  }, [location, trackingId]);

  return null;
};

export { GoogleAnalytics, TrackPageViews };
